<template>
	<div class="view view-stats" :class="$route.name">
		<div class="content">
			<main-title v-if="tab === 0" :text="'Stats (total cost years 1-' + (max_year + 1) + ': ' + total + ')'" />
			<main-title v-else text="Stats" />

			<div class="tabs">
				<div class="tab" :class="{ active: tab === 0 }" v-hammer:tap="() => tab = 0">Units</div>
				<div class="tab" :class="{ active: tab === 1 }" v-hammer:tap="() => tab = 1">Carbon reduction per country</div>
				<div class="tab" :class="{ active: tab === 2 }" v-hammer:tap="() => tab = 2">Global carbon reduction per year</div>
				<div class="tab" :class="{ active: tab === 3 }" v-hammer:tap="() => tab = 3">Average carbon reduction per country</div>
			</div>

			<div class="tab-content">
				<div class="scroll" v-scrollbar>
					<div>
						<stats-units v-if="tab === 0" :reacts="reacts" @change_index="(index) => max_year = index" />
						<carbon-reduction-per-country v-if="tab === 1" />
						<global-carbon-reduction-per-year v-if="tab === 2" />
						<average-carbon-reduction-per-country v-if="tab === 3" />
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import MainTitle from '@/components/game/main-title'
import StatsUnits from '@/components/game/stats/stats-units'
import CarbonReductionPerCountry from '@/components/game/stats/carbon-reduction-per-country'
import GlobalCarbonReductionPerYear from '@/components/game/stats/global-carbon-reduction-per-year'
import AverageCarbonReductionPerCountry from '@/components/game/stats/average-carbon-reduction-per-country'
import Apollo from '@/apollo/apollo-client'

export default {
	name: 'view-stats',
	data() {
		return {
			tab: 0,
			reacts: [],
			max_year: 0
		}
	},
	computed: {
		...mapGetters([ 'app', 'game', 'logged_user', 'progress' ]),
		questions() {
			return this.progress.questions.filter(q => q.majority_answer && q.majority_answer.unit_total)
		},
		total() {
			let total = 0
			this.questions
				.filter((q, index) => index <= this.max_year)
				.forEach(async question => {
					const a = this.reacts.find(r => parseInt(r.question_id) === parseInt(question.id))
					if (a && a.unit_details) {
						const detail = a.unit_details.find(u => parseInt(u.role_id) === parseInt(this.logged_user.role.id))
						if (detail) total += detail.score
					}
				})
			return total
		}
	},
	watch: {
		questions: {
			handler() {
				this.questions
					.forEach(async question => {
						const result = await Apollo.query('GET_OUTCOME_STAT', {
							room_id: parseInt(this.game.room.id),
							question_id: parseInt(question.id),
						})
						if (result) {
							const index = this.reacts.findIndex(r => parseInt(r.question_id) === parseInt(question.id))
							if (index === -1) {
								this.reacts.push({ ...result, question_id: question.id })
							} else {
								this.reacts[index] = { ...result, question_id: question.id }
							}
						}
					})
			},
			immediate: true
		}
	},
	components: { MainTitle, StatsUnits, CarbonReductionPerCountry, GlobalCarbonReductionPerYear, AverageCarbonReductionPerCountry }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


#app.tablet-horizontal, #app.tablet-vertical, #app.mobile
	.view-stats
		.content
			max-width none
			padding 16px 0 0 0
			.tabs .tab
				font-size 1.2rem
				margin 0 8px 0 0
			.tab-content
				height 'calc(100% - %s)' % (64px + 48px + 0)
					
#app.mobile
	.view-stats
		.content
			.tabs .tab
				font-size 1.2rem
				text-transform none
	

.view-stats
	.content
		height 100%
		width 100%
		padding 0 32px
		max-width calc(100% - 420px)
		.tabs
			height 48px
			flex flex-start flex-end
			// border-bottom 2px solid #405E6D
			box-shadow 0 2px var(--color-stats-tabs-border)
			z-index 1
			padding 0 16px
			.tab
				min-height @height
				width 100%
				margin 0 16px 0 0
				padding 4px 8px 2px 8px
				background-color var(--color-stats-tabs-bg)
				flex center center
				cursor pointer
				text-transform uppercase
				color #fff
				font-size 1.6rem
				line-height 1em
				text-align center
				&:last-child
					margin-right 0
				&.active
					opacity 1
					background-color var(--color-stats-tabs-bg-active)
					color var(--color-stats-tabs-text-active)
					text-decoration none
	.tab-content
		height 'calc(100% - %s)' % (64px + 48px + 16px)
		margin 0 16px
		background-color var(--color-stats-tab-content)
	

</style>
